

import { OccurrenceLocation } from './occurrence-location.model';
import { Witness } from './witness.model';
import { Contravention } from './contravention.model';
import { Recipient } from './recipient.model';
import { OperatorInformation } from './operator-information.model';
import { VehicleRegisteredOwnerInformation } from './vehicle-registered-owner-information.model';
import { Vehicle } from './vehicle.model';
import { VehicleSeizure } from './vehicle-seizure.model';
import { Warning } from './warning.model';
import { Document } from './document.model';
import { IssuanceResponse } from './issuance-response.model';

export class StopInformation {
    public stopInformationId: number = 0;
    public firstObservedTime: string;
    public firstObservedLocation: string;
    public observedByTypeId: number;
    public stopTime: string;
    public circumstanceTypeId: number;
    public circumstanceOther: string;
    public occurrenceLocationId: number;
    public roadConditionTypes: string;
    public roadConditionOther: string;
    public weatherConditionTypes: string;
    public weatherConditionOther: string;
    public trafficCondition: string;
    public visibilityConditionTypes: string;
    public visibilityConditionOther: string;
    public highwayCharacteristicsTypes: string;
    public highwayCharacteristicsOther: string;
    public roadSurfaceTypes: string;
    public roadSurfaceOther: string;
    public isPassengerInVehicle: boolean;
    public vehicleOccupants: string;
    public vehicleOccupantsNotes: string;
    public otherOfficerFirstName: string;
    public otherOfficerLastName: string;
    public multipleOfficerOperationIssuer: string;
    public multipleOfficerOperationObserver: string;
    public multipleOfficerOperationInterceptor: string;
    public multipleOfficerOperationInvestigator: string;
    public issueServiceTypeId: number;
    public officerTransportationTypeId: number;
    public officerTransportationOther: string;
    public officerVehicleTypeId: number;
    public officerVehicleNumber: string;
    public officerUniformTypeId: number;
    public officerUniformOther: string;
    public isNoTrafficStop: boolean;
    public submissionVersion: string;
    public occurrenceLocation: OccurrenceLocation;
    public witnesses: Witness[] = [];
    public contraventions: Contravention[] = [];
    public vehicleSeizures: VehicleSeizure[] = [];
    public warnings: Warning[] = [];
    public isVehicleCollision: boolean;
    public seatingTypeId: number;
    public isInterceptorSameAsIssuer: boolean;
    public isEmergencySubmission: boolean;
    public isPaperSubmission: boolean;
    public officerSubmissionDeclarationTypeId: number = 0;
    public hasBodycamVideo: boolean;
    public documents: Document[] = [];
    public officerAdditionalDetailsDeclarationTypeId: number;

    //Non DB fields
    public tempFileFolder: string;
    public recipient: Recipient;
    public operatorInformation: OperatorInformation;
    public vehicleRegisteredOwnerInformation: VehicleRegisteredOwnerInformation;
    public vehicle: Vehicle;
    public isIRSSelected: boolean;
    public isSDPSelected: boolean;
    public isTSASDPSelected: boolean;
    public isSpeedingSelected: boolean;
    public isTSASelected: boolean;
    public issueDate: Date;
    public isMobilePrint: boolean = false;
    public progressId: number = 1;
    public isNoticePrinted: boolean = false;
    public isDetailSubmitted: boolean = false;
    public isOnlySDPActive: boolean = false;
    public apisStatus: string;
    public hasChangesDeclaration: boolean = false;
    public courtCode: string = "";
    public courtDate: Date;
    public courtTime: string;
    public courtEndTime: string;
    public issuanceResponse: IssuanceResponse;
    public availableTSA: boolean = false;
    public ticketPayCentreTypeId: number;
    public isOtherOfficerInvolved: boolean;
    public otherOfficerName: string;
    public userIsAddingDetails: boolean = false;
    public policeFileNumber: string;
    public isLEASubmissionDeadlinePassed: boolean;
    public reReviewDocumentReferenceNumber: string;
    public updatedOn: Date;

    constructor(init?: Partial<StopInformation>) {
        
        Object.assign(this, init);
    }
}
