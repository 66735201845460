

import { Charge } from './charge.model';
import { Jurisdiction } from './jurisdiction.model';
import { Fine } from './fine.model';
import { RoadsideAppeal } from './roadside-appeal.model';
import { StopInformation } from './stop-information.model';
import { ImpairmentScreeningInformation } from './impairment-screening-information.model';
import { VehicleSeizure } from './vehicle-seizure.model';
import { Vehicle } from './vehicle.model';
import { SpeedingTicket } from './speeding-ticket.model';
import { Recipient } from './recipient.model';
import { Document } from './document.model';
import { ContraventionPayment } from './contravention-payment.model';
import { Review } from './review.model';
import { Event } from './event.model';
import { PaymentTimeRequest } from './payment-time-request.model';
import { PaymentAdditionalTimeRequest } from './payment-additional-time-request.model';
import { PaymentAdditionalTimeRequest2 } from './payment-additional-time-request2.model';
import { PoliceServiceInformation } from './police-service-information.model';
import { NoticeCancellationRequestMapping } from './notice-cancellation-request-mapping.model';
import { LateReviewRequestItem } from './late-review-request-item.model';
import { ReviewItem } from './review-item.model';
import { JudicialReview } from './judicial-review.model';

export class Contravention {
    public contraventionId: number = 0;
    public policeFileNumber: string = "";
    public recipientId: number;
    public chargeId: number;
    public jurisdictionId: number;
    public contraventionNumber: string;
    public occurrenceDate: Date;
    public occurrenceTime: string;
    public issueDate: Date;
    public contraventionTypeId: number = 0;
    public contraventionStatusTypeId: number;
    public contraventionDetails: string;
    public suspensionDuration: number = 0;
    public suspensionDurationType: string;
    public isLicenceSeized: boolean = false;
    public isLicenceDestroyed: boolean = false;
    public stopInformationId: number = 0;
    public contraventionGroundsTypeId: number;
    public impairmentScreeningInformationId: number;
    public reasonableGroundsDetails: string;
    public consumptionEvidence: string;
    public additionalNotes: string;
    public isNoVehicleSeizureMade: boolean;
    public noVehicleSeizureDetails: string;
    public isReasonableGroundsToBelieve: boolean;
    public policeOfficerFullName: string;
    public submissionDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    public submissionTime: string;
    public isTrueInformation: boolean = false;
    public officerSubmissionDeclarationTypeId: number = 0;
    public reviewRequestExtensionDate: Date;
    public isReServeRequired: boolean;
    public reServeReason: string;
    public reServeDate: Date;
    public reServeMethodTypeId: number;
    public licencePlateNumber: string;
    public cancelledDate: Date;
    public vehicleId: number;
    public isOverdueFineNoticeGenerated: boolean;
    public demerits: number;
    public speedingTicketId: number;
    public payCentreCode: string;
    public mailoutDate: Date;
    public isParking: boolean;
    public charge: Charge;
    public jurisdiction: Jurisdiction;
    public fine: Fine;
    public roadsideAppeal: RoadsideAppeal;
    public stopInformation: StopInformation;
    public impairmentScreeningInformation: ImpairmentScreeningInformation;
    public vehicleSeizure: VehicleSeizure;
    public vehicle: Vehicle;
    public speedingTicket: SpeedingTicket;
    public recipient: Recipient;
    public documents: Document[] = [];
    public payments: ContraventionPayment[] = [];
    public reviewItems: ReviewItem[] = [];
    public events: Event[] = [];
    public paymentTimeRequests: PaymentTimeRequest[] = [];
    public paymentAdditionalTimeRequests: PaymentAdditionalTimeRequest[] = [];
    public paymentAdditionalTimeRequest2List: PaymentAdditionalTimeRequest2[] = [];
    public lateReviewRequestItems: LateReviewRequestItem[] = [];
    public noticeCancellationRequestMappings: NoticeCancellationRequestMapping[] = [];
    public policeServiceInformation: PoliceServiceInformation[] = [];
    public timeToPayState: number = 0;
    public timeToPayRequestDeadLine: Date;
    public timeToPayApprovalDate: Date;
    public additionalTimeToPayEligibleOn: Date;
    public review: Review;
    public reviewState: number = 0;
    public reviewDeadLine: Date;
    public isCancellationInProgress: boolean = false;
    public isRecipientYouth: boolean;
    public locationTypeName: string;
    public contraventionTypeName: string;
    public secondaryContraventionTypeId: number = 0;
    public tertiaryContraventionTypeId: number = 0;
    public isWarning: boolean = false;
    public stopSubmissionVersion: string;
    public judicialReviews: JudicialReview[] = [];
    public additionalSuspension: string;
    public remedialEducation: string;
    public licencePickupAddress: string;
    public licencePickupDetachment: string;
    public returnLicenceByMail: boolean = false;
    public offenceTypeId: number = 0;
    public officerAdditionalDetailsDeclarationTypeId: number;
    public additionalDetailsDate: Date;
    public updatedOn: Date;

    constructor(init?: Partial<Contravention>) {
        
        Object.assign(this, init);
    }
}
